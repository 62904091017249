@font-face {
  font-family: Metropolis;
  font-weight: 400;
  src: url("/fonts/Metropolis/Regular.woff2") format("woff2");
}

@font-face {
  font-family: Metropolis;
  font-weight: 500;
  src: url("/fonts/Metropolis/Medium.woff2") format("woff2");
}

@font-face {
  font-family: Metropolis;
  font-weight: 600;
  src: url("/fonts/Metropolis/SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: Metropolis;
  font-weight: 700;
  src: url("/fonts/Metropolis/Bold.woff2") format("woff2");
}

@font-face {
  font-family: Chilldranety;
  src: url("/fonts/Chilldranety-400.ttf") format("truetype");
}
