.logo {
  margin: 0 auto 1rem;
  display: block;
}

.home-- {
  &title {
    color: $white;
    font: 600 1.375rem/1.625rem Metropolis;
    text-align: center;
    margin: 3.125rem auto 0;
  }

  &subtitle {
    color: $textGrey;
    font: 400 1rem/1.25rem Metropolis;
    text-align: center;
    margin-bottom: 1.75rem;
  }
}

.hs-- {
  &title {
    color: $yellow;
    font: 600 1.375rem/1.5rem Metropolis;
    text-align: center;
    margin-bottom: 0;
  }

  &title-left {
    @extend .hs--title;
    text-align: left;
  }

  &gradient {
    background: url("/assets/gradient.png") no-repeat center;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }
}

.teaser-section {
  padding: 3rem 1.5rem;
}

.rc-title {
  @extend .home--subtitle;
  color: $white;
  margin-bottom: 0 !important;
}

.rc-icon {
  height: 2rem;
  width: auto;
  margin-bottom: 1rem;
}

.rc-video {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.env-- {
  &wrapper {
    margin: 0 1.25rem;
    position: relative;
    padding-bottom: 3rem;
  }

  &img {
    width: 100%;
    height: auto;
  }

  &container {
    position: absolute;
    bottom: 5.5rem;
    padding: 0 1.5rem;

    .fc--subtitle {
      margin-bottom: 2.25rem;
      max-width: 100%;
    }
  }
}

.header-- {
  &container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 2rem 12rem;
    z-index: 100;
    transition: background-color 0.2s ease;
  }

  &links {
    list-style: none;
    float: right;
    li {
      display: inline-block;
      padding-left: 2.5rem;

      &::before {
        content: "";
      }
    }
    a {
      color: $textGrey;
      font: 400 1.25rem/1.5rem Metropolis;
      text-decoration: none;
      cursor: pointer;
    }

    a:hover {
      color: $white;
    }
  }
}

#doodle,
#gift {
  padding-top: 6rem;
}

.modal-video {
  backdrop-filter: blur(5px);
  width: 100vw;
  height: 100vh;
}

.modal-video-close-btn {
  transform: translate(1.25rem, 2.5rem);
  cursor: pointer;
  outline: none;
}

.teaser-thumb {
  height: 90px;
  width: 120px;
  background: url("/assets/thumbnail.png") no-repeat center;
  background-size: cover;

  img {
    height: 40px;
    margin: auto;
    backdrop-filter: blur(5px);
    border-radius: 50%;
  }
}

.logo-mob-header {
  margin: 2rem auto 0;
  display: block;
}

@media (min-width: 769px) {
  .logo {
    width: 20rem;
    height: auto;
    margin: 0 0 2.5rem;
  }

  .home-- {
    &title {
      font: 600 3.25rem/4rem Metropolis;
      text-align: left;
      margin-bottom: 0;
    }

    &subtitle {
      font: 400 2.25rem/2.75rem Metropolis;
      text-align: left;
      margin: 1rem 0 2.5rem;
    }
  }

  .hs-- {
    &title {
      font: 700 3.25rem/4rem Metropolis;
      text-align: left;
    }
  }

  .teaser-section {
    display: flex;
    padding: 8rem 0;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .env-- {
    &wrapper {
      margin: 0;
      padding-bottom: 6rem;
    }

    &container {
      width: 60%;
      left: 15%;
      bottom: 25%;
      top: 25%;
      .fc--subtitle {
        font-size: 1.25rem;
        text-align: left;
        line-height: 1.75rem;
      }
    }
  }

  #doodle,
  #gift {
    padding-top: 12rem;
  }

  .teaser-thumb {
    height: 190px;
    width: 260px;
    margin: 0 3.5rem 0 0;
    cursor: pointer;

    img {
      height: 80px;
      backdrop-filter: blur(2px);
    }
  }
}
