$bgDark: #121212;
$bgLighter: #1f1f21;
$bgLighter2: #27272a;
$bgLighter3: #2a2a2d;
$bgGrey: #212121;
$white: #ffffff;
$offWhite: #f5f6f7;
$textGrey: #a1a1a1;
$textGrey2: #828282;
$textGrey3: #4f4f4f;
$yellow: #fbaf03;
$tagGreen: #27ae60;
