.cb-- {
  &container {
    padding: 0.75rem 2rem;
    border-radius: 70px;
    width: max-content;
    margin: 0 auto;
    background: linear-gradient(180deg, #eea602 0%, #f79223 50%, #c56d09 100%);
    cursor: pointer;
  }

  &label {
    font: 700 0.875rem/1.25rem Metropolis;
    color: black;
  }

  &icon-btn {
    margin-left: 0.625rem;

    svg {
      transform: translateY(3px);
    }

    path {
      fill: black;
    }
  }
}

@media (min-width: 769px) {
  .cb-- {
    &container {
      margin: 0;
      padding: 1.25rem 2.5rem;
    }
    &label {
      font: 600 1.5rem/1.5rem Metropolis;
    }

    &icon-btn {
      transform: scale(2);

      svg {
        transform: translateY(0);
      }
    }
  }
}
