$black: #000000;
$white: #ffffff;
$primary-orange: #f5a623;
$heading-grey: rgba(0, 0, 0, 0.7);
$text-light-grey: #a1a1a1;
$text-bright-grey: #c1c1c1;
$text-grey: rgba(0, 0, 0, 0.58);
$background-header-text-grey: #b8b4b4;
$shadow-grey: rgba(0, 0, 0, 0.06);
$shadow-hover-grey: rgba(0, 0, 0, 0.1);
$background-off-white: #f7f6f3;
$background-dark-grey: #1e1e1e;
$background-light-grey: #313131;
$scroll-bar-grey: #4d4b4b;
$card-background-grey: #1f1f21;
$hashtag-grey: #a1a1a1;
$error-red: #ce3333;
$success-green: #00ab66;
$footer-background-grey: #ececec;
$footer-grey: #5e5e5e;
$yellow: #fbaf03;
