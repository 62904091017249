.fc-- {
  &card {
    padding: 3.75rem 1.25rem 2.5rem;
    border-radius: 12px;
    margin: 0.75rem auto;
    width: 100%;
    max-width: 20rem;
    height: 23.4375rem;
    position: relative;
    overflow: hidden;
  }

  &title {
    font: 600 1.25rem/1.625rem Metropolis;
    text-align: center;
    color: $white;
    margin: 3rem auto 0.625rem;
    max-width: 16.25rem;
  }

  &subtitle {
    color: $textGrey;
    font: 400 0.875rem/1.25rem Metropolis;
    text-align: center;
    margin: 0 auto;
    max-width: 16.25rem;
  }

  &underline {
    width: 75%;
    margin-bottom: 1.25rem;
  }

  &list {
    color: $white;
    font: 400 0.875rem/1rem Metropolis;
    list-style: none;
    padding: 0;
    margin: 2.25rem auto 3re m 0;

    li {
      margin-bottom: 0.625rem;
    }
  }

  &coin {
    height: 2rem;
    margin-bottom: -1.75rem;
    margin-top: 1.875rem;
  }
}

.rc-subtitle {
  @extend .fc--title;
  color: $textGrey;
  text-align: left;
  font-weight: 400;
  margin: 0.75rem auto 0 0;
  max-width: 25rem;
}

@media (min-width: 769px) {
  .fc-- {
    &card {
      margin: 1.25rem 0;
    }

    &title {
      font-weight: 400;
    }

    &subtitle {
      font-size: 0.875rem;
    }
  }
}
