.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.dc-- {
  &section {
    background-color: $bgLighter;
    padding-bottom: 2.5rem;
    margin-bottom: 2rem;
    margin-top: 5.625rem;
  }

  &card-container {
    width: 240px;
    margin: 0 auto;
    position: relative;
    transform: translateY(-3.75rem);
  }

  &card {
    width: 100%;
    height: auto;
  }

  &card-doodle {
    width: 60%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
  }

  &img-btn {
    padding: 0.625rem 0.5rem;
    background-color: $bgLighter3;
    border-radius: 6px;
    width: max-content;
    margin-right: 0.75rem;
    cursor: pointer;
    @extend .no-select;
  }

  &btn-doodle {
    height: 1.5rem;
    width: auto;
    opacity: 0.4;
    transform: scale(1.5);
  }

  &img-btn-active {
    background-color: $bgDark;
    padding: 1.2rem 1rem;

    .dc--btn-doodle {
      opacity: 1;
      transform: scale(1.8);
      transition: opacity 0.5s ease-out;
    }
  }

  &carousel-wrapper {
    overflow-x: scroll;
    width: calc(100vw - 6rem);
    margin: 2rem auto;
    scrollbar-width: none;
  }

  &carousel-wrapper::-webkit-scrollbar {
    display: none;
  }

  &carousel-main {
    position: relative;
    margin-top: -5rem;
  }

  &carousel {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &arrow-left,
  &arrow-right {
    position: absolute;
    top: 50%;
    cursor: pointer;
    @extend .no-select;
  }

  &arrow-left {
    left: 1.5rem;
    transform: rotate(180deg) translateY(50%);
  }

  &arrow-right {
    right: 1.5rem;
    transform: translateY(-50%);
  }

  &sec-banner {
    display: none;
  }
}

@media (min-width: 769px) {
  .dc-- {
    &section {
      background-color: transparent;
      margin-top: 8.25rem;
    }

    &sec-banner {
      display: block;
      position: absolute;
      background-color: $bgLighter;
      height: 200px;
      width: 100vw;
      left: 0;
      margin-top: 1rem;
    }

    &card-container {
      float: right;
      width: 320px;
      transform: rotate(-25.17deg) translateY(-150px);
    }

    &carousel-wrapper {
      width: calc(100% - 6rem);
      margin: auto;
    }

    &carousel {
      height: 200px;
    }

    &carousel-main {
      width: 40%;
    }

    &btn-doodle {
      height: 2.75rem;
    }

    &img-btn {
      padding: 0.6rem;
      margin-right: 1rem;

      img {
        transform: scale(1);
      }
    }

    &img-btn-active {
      padding: 1.25rem;
      box-shadow: (0px 4px 7px rgba(0, 0, 0, 0.25));

      .dc--btn-doodle {
        opacity: 1;
        transform: scale(1.5);
        transition: opacity 0.5s ease-out;
      }
    }
  }
}

@media (max-width: 1280px) and (min-width: 769px) {
  .dc-- {
    &card-container {
      width: 200px;
      transform: rotate(-25.17deg) translateY(0px);
    }
  }
}
