.footer-- {
  &container {
    padding: 5rem 1.5rem 7.5rem;
    border-top: 1px solid #303033;
  }

  &content {
    font: 400 0.875rem/1.25rem Metropolis;
    color: $textGrey;
    margin: 1.5rem 0 2.25rem;
    text-align: justify;
  }

  &cta {
    color: $yellow;
    font: 400 1rem/2rem Metropolis;
    text-decoration: none;

    path {
      fill: $yellow;
    }

    svg {
      margin-left: 0.75rem;
      transform: translateY(2px);
    }
  }
}

@media (min-width: 768px) {
  .footer-- {
    &container {
      padding: 9.375rem 0 5.625rem;
      margin-top: 6rem;

      img {
        width: 146px;
      }
    }

    &content,
    &cta {
      font: 400 1.25rem/2rem Metropolis;
    }
  }
}
