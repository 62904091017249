@import "./partials/colors";
@import "./partials/fonts";
@import "./famcardStyles/variables/fonts";
@import "./famcardStyles/variables/colors";
@import "./famcardStyles/layouts/containers";
@import "./famcardStyles/pages/home";
@import "./famcardStyles/components/customButton";
@import "./famcardStyles/components/featureCard";
@import "./famcardStyles/components/faqs";
@import "./famcardStyles/components/doodleCarousel";
@import "./famcardStyles/components/footer";

* {
  box-sizing: border-box;
  font-family: Metropolis;
}

html {
  font-size: 1rem;
  scroll-behavior: smooth;
}

body {
  background-color: $bgDark;
  overflow-x: hidden;
  margin: 0;
}

@media (max-width: 1399px) {
  html {
    font-size: 0.95rem;
  }
}

@media (max-width: 767px) {
  html {
    font-size: 0.9rem;
  }
}

@media (max-width: 575px) {
  html {
    font-size: 0.8rem;
  }
}

// Layout
.container-fluid,
.container {
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.col,
[class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.header_section_background {
  background-color: $black;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  margin: 0;
  width: 100%;
  z-index: -1;
}

.below_header_container {
  background-color: $white;
  margin-top: 100vh;
  z-index: 0;
}
.below_header_container_dark {
  background-color: $background-dark-grey;
  margin-top: 100vh;
}

.dark_bg {
  background-color: $background-dark-grey;
}

.section_vertical_spacing {
  background-repeat: no-repeat;
  padding-bottom: 6rem;
  padding-top: 6rem;

  .heading_text {
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
}

.full_width_section {
  margin: 6rem 0;
  padding-left: 7%;
  padding-right: 7%;

  .heading_text {
    font-weight: 700;
    margin-bottom: 0;
  }
}

.extra_sides_padding,
.full_width_section {
  .heading_text {
    font-weight: 700;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1375px;
  }
}

@media (max-width: 1599px) {
  .extra_sides_padding {
    margin-left: 7%;
    margin-right: 7%;
    max-width: 86%;
  }
}

@media (max-width: 1399px) {
  .extra_sides_padding {
    margin-left: 4%;
    margin-right: 4%;
    max-width: 92%;
  }

  .full_width_section {
    padding-left: 4%;
    padding-right: 4%;
  }
}

@media (max-width: 991px) {
  .extra_sides_padding {
    margin-left: 5%;
    margin-right: 5%;
    max-width: 90%;
  }

  .section_vertical_spacing {
    padding-bottom: 3.5rem;
    padding-top: 3.5rem;
  }

  .full_width_section {
    margin-bottom: 3.5rem;
    margin-top: 3.5rem;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (max-width: 575px) {
  .section_vertical_spacing {
    padding-bottom: 4.5rem;
    padding-top: 4.5rem;
  }

  .full_width_section {
    margin-bottom: 3.5rem;
    margin-top: 3.5rem;
    padding-left: 20px;
    padding-right: 20px;
  }

  .extra_sides_padding {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

// Text
.heading_text {
  font-size: 3.3rem;
  font-weight: 700;
  line-height: 4.2rem;
}

.subheading_text {
  font-size: 2rem;
  font-weight: 600;
  line-height: 3rem;
}

.paragraph_text {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.15rem;
}

.subparagraph_text,
.subparagraph_text p {
  font-size: 1.2rem;
  line-height: 2rem;
}

ul {
  padding-inline-start: 0;
  position: relative;

  > li {
    list-style: none;
    margin: 0;
    padding: 0.8rem 0 0.4rem 2.1rem;
    vertical-align: middle;

    &:before {
      content: url(../../public/assets/localImages/listIcon.svg);
      display: inline-block;
      height: 2.15rem;
      margin-left: -2.15rem;
      margin-top: -2.15rem;
      position: relative;
      top: 0.1rem;
      width: 2.15rem;
    }
  }
}

@media (max-width: 1399px) {
  .heading_text {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .subheading_text {
    font-size: 1.9rem;
    line-height: 2.5rem;
  }
}

@media (max-width: 767px) {
  .heading_text {
    font-weight: 700;
    font-size: 2.6rem;
    line-height: 3.2rem;
  }

  .paragraph_text {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.9rem;
  }

  .subparagraph_text,
  .subparagraph_text p {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }

  ul {
    > li {
      padding: 0.8rem 0 0.4rem 2.1rem;

      &:before {
        top: 0.15rem;
      }
    }
  }
}

@media (max-width: 575px) {
  .heading_text {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 3rem;
  }

  .heading_text_sm {
    font-size: 1.9rem;
    line-height: 2.8rem;
    font-weight: 700;
  }

  .subheading_text {
    font-size: 1.8rem;
    line-height: 2.3rem;
  }

  .paragraph_text {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 2.1rem;
  }

  .subparagraph_text,
  .subparagraph_text p {
    font-size: 1.2rem;
    line-height: 2.1rem;
  }

  ul {
    > li {
      padding: 0.8rem 0 0.4rem 2.1rem;

      &:before {
        top: 0.35rem;
      }
    }
  }
}

// Color Texts
.white_text,
.white_text p {
  color: $white;
}

.light_grey_text,
.light_grey_text p {
  color: $text-light-grey;
}

.grey_text,
.grey_text p {
  color: $text-grey;
}

.light_black_text,
.light_black_text p {
  color: $heading-grey;
}

.primary_orange_text {
  color: $primary-orange;
}

.error_text {
  color: $error-red;
  font-weight: 500;
}

.success_text {
  color: $success-green;
  font-weight: 500;
}

// Anchor
a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &.active {
    text-decoration: none;
  }
}

.primary_link {
  color: $primary-orange;

  &:hover {
    color: $primary-orange;
  }
}

// Utilities
.navbar_announcement_vertical_spacing {
  background-color: #fff;
  padding-top: 210px;
}

.navbar_vertical_spacing {
  padding-top: 150px;
}

.section_image_container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;

  .image_content {
    height: auto;
    max-height: 25rem;
    max-width: 90%;
    width: auto;
  }
}

.section_text_container {
  align-items: center;
  display: flex;
}

.section_right_image {
  justify-content: flex-end;
  order: 2;
  text-align: right;
}

.vertical_separator {
  background-color: $shadow-grey;
  height: 30%;
  margin-top: 13.5rem;
  transform: rotate(90);
  width: 1px;
}

.dotted_separator {
  border: 0;
  border-top: 1px dashed $text-light-grey;
  border-width: 2px;
  width: 100%;
}

@media (max-width: 1599px) {
  .section_image_container {
    .image_content {
      max-height: 22rem;
    }
  }

  .section_text_container {
    align-items: center;
    display: flex;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .navbar_announcement_vertical_spacing {
    padding-top: 195px;
  }

  .navbar_vertical_spacing {
    padding-top: 135px;
  }

  .section_image_container {
    .image_content {
      max-height: 22rem;
    }
  }
}

@media (max-width: 1199px) {
  .vertical_separator {
    display: none;
  }
}

@media (max-width: 991px) {
  .full_width_section {
    .section_image_container {
      justify-content: center;
    }
  }

  .section_image_container {
    justify-content: flex-start;
    padding-bottom: 1rem;

    .image_content {
      max-height: 21rem;
    }
  }

  .section_right_image {
    order: initial;
  }

  .navbar_announcement_vertical_spacing {
    padding-top: 195px;
  }

  .navbar_vertical_spacing {
    padding-top: 135px;
  }
}

@media (max-width: 767px) {
  .section_image_container {
    justify-content: flex-start;

    .image_content {
      max-height: 20rem !important;
    }
  }

  .section_text_container {
    > div {
      max-width: 95%;
    }
  }
}

@media (max-width: 575px) {
  .navbar_announcement_vertical_spacing {
    padding-top: 145px;
  }

  .navbar_vertical_spacing {
    padding-top: 80px;
  }

  .section_image_container {
    .image_content {
      max-height: 20rem !important;
    }
  }

  .section_text_container {
    > div {
      max-width: 100%;
    }
  }
}

// TBC - old styles to be removed once their
// dependency is cleared off FAQs and Careers page
.main_head {
  height: auto;
  background: #fff;
  z-index: 1000;
  width: 100%;
  overflow-x: hidden;
}

@media (max-width: 340px) {
  .heading_text {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
}

/* For routes rendering pdf */
.wrap {
  padding: 12% 50px 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.controls {
  margin: 20px 0px;
  display: flex;
  gap: 40px;
}
.controls button {
  padding: 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background: white;
}
.controls button:disabled {
  background: gray;
  cursor: not-allowed;
}
.controls button .prev {
  transform: rotate(180deg);
}
.controls button img {
  height: 20px;
  width: 20px;
}

.react-pdf__Page__textContent {
  user-select: text;
}
