.centered-div {
  width: max-content;
  margin: auto;
}

.flex-container-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.padded-section {
  padding: 3rem 1.5rem;
}

.placeholder {
  background: $bgGrey;
  border-radius: 1.25rem;
  margin: auto;
}

.flex-column-centered {
  @extend .flex-container-wrap;
  flex-wrap: nowrap;
  flex-direction: column;
}

.carousel-wrapper {
  width: 100vw;
  overflow-x: scroll;
  margin: 0 -1.5rem;
  scrollbar-width: none;
}

.carousel-wrapper::-webkit-scrollbar {
  display: none;
}

.carousel-container {
  padding-left: 1.5rem;
  display: flex;
  width: max-content;
  flex-wrap: nowrap;
}

.carousel-item {
  flex-shrink: 0;
  border-radius: 0.75rem;
  height: 23.4375rem;
  width: 17.5rem;
  margin-right: 1.25rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.resp-card-cont {
  @extend .flex-container-wrap;
}

.full-width-card {
  @extend .flex-container-wrap;
  background-color: transparent;
  border-radius: 12px;
  margin: 3rem 0;
  margin-right: auto;
  padding: 3.25rem 3.75rem;
  overflow: hidden;
  height: 375px;
  max-width: 1080px;
  position: relative;
}

.master-container {
  max-width: 1400px;
  margin: 0 auto;
}
.padding-container {
  @media (min-width: 769px) {
    padding: 6rem 12rem;
  }
}
.video-bg {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 769px) {
  .flex-container-wrap {
    justify-content: space-between;
    width: 100%;
  }
  .padded-section {
    padding: 6rem 0;
  }
  .resp-card-cont {
    align-items: stretch;
    margin-top: 3.75rem;
  }

  .carousel-wrapper {
    width: calc(100vw - 10rem);
  }

  .carousel-container {
    padding-right: 17.5rem;
  }
}
