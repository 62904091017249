.faq-- {
  &ques {
    font: 500 1rem/1.3rem Metropolis;
    color: $white;
    margin: 0.75rem 0 0;
    width: 90%;
    display: inline-block;
  }

  &ans {
    font: 400 0.875rem/1.25rem Metropolis;
    color: $textGrey;
    margin: 0.75rem 0 2.5rem;
  }

  &cont {
    border-radius: 0.75rem;
    background-color: $bgLighter;
    padding: 2rem 1.25rem;
    margin-top: 0.75rem;
  }

  &hr {
    border: none;
    border-bottom: 1px solid #303033;
    display: block;
  }

  &expanded {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
  }

  &hidden {
    max-height: 0;
    margin: 0.75rem 0;
    transition: 0.05s ease-out;
    overflow: hidden;
  }

  &toggle {
    float: right;
    margin: 0.75rem 0.5rem 0;
    svg {
      transform: rotateZ(90deg);
      transition: transform 0.25s ease;
    }

    path {
      fill: $textGrey2;
    }
  }

  &toggle:hover {
    cursor: pointer;
    path {
      fill: $white;
      transition: fill 0.15s ease;
    }
  }
}

.reverse {
  svg {
    transform: rotateZ(-90deg);
  }
}

@media (min-width: 769px) {
  .faq-- {
    &ques {
      font: 400 1.75rem/2.5rem Metropolis;
      margin-top: 2.5rem;
    }

    &ans {
      font: 400 1rem/1.75rem Metropolis;
      max-width: 43.75rem;
    }

    &cont {
      padding: 1.25rem 3.75rem;
      margin-top: 3.75rem;
    }

    &toggle {
      margin: 3rem 1rem 0;
      transform: scale(1.5);
    }

    &hidden {
      margin: 0 0 2.5rem;
    }
  }

  .faq--hr:last-child {
    display: none;
  }
}
