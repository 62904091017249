@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 400;
  src: local("Metropolis"), local("MetropolisRegular"),
    url(../../fonts/Metropolis/Regular.woff2) format("woff2");
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF;
}
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 500;
  src: local("Metropolis"), local("MetropolisMedium"),
    url(../../fonts/Metropolis/Medium.woff2) format("woff2");
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF;
}
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 600;
  src: local("Metropolis"), local("MetropolisSemiBold"),
    url(../../fonts/Metropolis/SemiBold.woff2) format("woff2");
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF;
}
@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 700;
  src: local("Metropolis"), local("MetropolisBold"),
    url(../../fonts/Metropolis/Bold.woff2) format("woff2");
  unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF;
}

@font-face {
  font-family: WaitingForTheSunrise;
  src: url(../../fonts/WaitingForTheSunrise/WaitingfortheSunrise.ttf)
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Chilldranety;
  src: url(../../fonts/Chilldranety/Chilldranety-400.ttf) format("truetype");
}
